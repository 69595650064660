<template>
    <div>
        <base-header type="gradient-dark" class="pb-3 pt-3 pt-md-5">
            <div class="row">
                <div class="col-xl-4 col-lg-4">
                    <stats-card title="Gelir" type="gradient-red" :sub-title="gelir" icon="fas fa-lira-sign" class="mb-4 mb-xl-0">
                        <template slot="footer">
                            <span class="text-nowrap">{{ zamanAdi }}</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-4 col-lg-4">
                    <stats-card title="Gider" type="gradient-red" :sub-title="gider" icon="fas fa-lira-sign" class="mb-4 mb-xl-0">
                        <template slot="footer">
                            <span class="text-nowrap">{{ zamanAdi }}</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-4 col-lg-4">
                    <stats-card title="Toplam" type="gradient-red" :sub-title="toplam" icon="fas fa-lira-sign" class="mb-4 mb-xl-0">
                        <template slot="footer">
                            <span class="text-nowrap">{{ zamanAdi }}</span>
                        </template>
                    </stats-card>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mb-2 mt-2">
            <div class="row">
                <div class="col-md-4">
                    <label for="subeler">Şubeler</label>
                    <select @change="zamanaGoreFiltrele" v-model="subeID" class="form-control" id="subeler">
                        <option value="0">Tümü</option>
                        <option v-for="(sube, index) in subeler" :key="index" :value="sube.MusteriID">{{ sube.FirmaAdi }}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="tarih-araligi">Tarih Aralığı</label>
                    <select v-model="zaman" @change="zamanaGoreFiltrele" id="tarih-araligi" class="form-control">
                        <option value="1">Bugün</option>
                        <option value="2">Dün</option>
                        <option value="3">Bu Hafta</option>
                        <option value="4">Geçen Hafta</option>
                        <option value="5">Bu Ay</option>
                        <option value="6">Geçen Ay</option>
                        <option value="-1">Özel</option>
                    </select>
                </div>
                <div v-if="zaman == -1" class="col-md-4">
                    <label class="col-md-6 mt-2" for="tarih-1">Başlangıç Tarihi <input @input="zamanaGoreFiltrele" class="form-control mt-2" type="date" v-model="tarih1"/></label>
                        
                    <label class="col-md-6 mt-2" for="tarih-2">Bitiş Tarihi <input @input="zamanaGoreFiltrele" class="form-control mt-2" type="date" v-model="tarih2"/></label>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>Ödeme Şekli</th>
                                        <th>Tutar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(odemeSekli, index) in odemeSekilleri" :key="index">
                                        <td>{{ odemeSekli.OdemeSekliAdi }}</td>
                                        <td>{{ paraFormatla(odemeSekli.OdemeSekliToplam) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 col-md-7">
                        <odeme-sekilleri-chart type="pie" id="odeme-sekilleri-chart" ref="odemeSekilleriChart" :chart-data="toplamData" :labels="labels" :background-color="backgroundColor" :options="options"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import api from '@/api/api'

import paraFormatla from '@/utils/paraFormatla'
import tarihFormatla from '@/utils/tarihFormatla'
import randomColor from '@/utils/randomColor'
import OdemeSekilleriChart from './Charts/BaseChart'

import moment from 'moment'



export default {
    name: 'OdemeSekilleriRaporu',
    components: {
        OdemeSekilleriChart
    },
    data() {
        return {
            subeID: 0,
            subeler: 0,
            gelir: '0',
            gider: '0',
            toplam: '0',
            zaman: 3,
            zamanAdi: '',
            tarih1: moment().format('YYYY-MM-DD'),
            tarih2: moment().format('YYYY-MM-DD'),
            type: 'light',
            odemeSekilleri: [],
            options: {
                legend: {
                    display: true,
                    position: 'top'
                },
                showAllTooltips: false,
                responsive: true, 
                maintainAspectRatio: true,
            },
            labels: [],
            toplamData: [],
            backgroundColor: []
        }
    },
    mounted() {
        this.subelerGetir()
    },
    methods: {
        initChart(odemeSekilleriData) {

            this.labels = odemeSekilleriData.map(odemeSekli => odemeSekli.OdemeSekliAdi)
            this.backgroundColor = odemeSekilleriData.map(() => randomColor())
            this.toplamData = odemeSekilleriData.map(odemeSekli => {
                let odemeSekliToplami = 0
                try {

                    
                    
                    odemeSekliToplami = odemeSekli.OdemeSekliToplam //new Intl.NumberFormat('tr', { maximumSignificantDigits: 4 }).format(odemeSekli.OdemeSekliToplam)

                    if (odemeSekliToplami.includes(',')) {
                        odemeSekliToplami = odemeSekliToplami.replace(',', '.')
                    }
                    
                } catch (error) {
                    odemeSekliToplami = '0.00'
                }

                return odemeSekliToplami
            })
        },
        odemeSekilleriGetir() {
            const form = new FormData()

            const { MusteriNo } = api.getUser()

            form.append('MusteriNo[]', MusteriNo)
            form.append('zaman', this.zaman)
            form.append('baslangic-tarihi', this.tarih1)
            form.append('bitis-tarihi', this.tarih2)

            this.zamanAdiYukle()

            api
            .odemeSekilleriGetir(form)
            .then(resp => resp.json())
            .then(data => {
                const odemeSekilleri = data.kasa.length ? data.kasa : []
                const gelirGider = data.gelir_gider.length ? data.gelir_gider[0] : false

                this.odemeSekilleri = odemeSekilleri

                this.initChart(odemeSekilleri)

                this.gelir = this.paraFormatla(0)
                this.gider = this.paraFormatla(0)
                this.toplam = this.paraFormatla(0)

                if (gelirGider) {
                    this.gelir = this.paraFormatla(gelirGider.Gelir)
                    this.gider = this.paraFormatla(gelirGider.Gider)
                    this.toplam = this.paraFormatla(gelirGider.GelirGiderToplam)
                }
            })
        },
        subelerGetir() {
            const subelerData = new FormData()

            const { MusteriNo } = api.getUser()

            subelerData.append('MusteriNo', MusteriNo)

            api
            .subelerGetir(subelerData)
            .then(resp => resp.json())
            .then(data => {
                this.subeler = data[0]

                this.odemeSekilleriGetir()
            })
        },
        zamanAdiYukle() {
          
            switch (this.zaman.toString()) {
                case '1':
                    this.zamanAdi = 'Bugün'
                    break;
                case '2':
                    this.zamanAdi = 'Dün'
                    break;
                case '3':
                    this.zamanAdi = 'Bu Hafta'
                    break;
                case '4':
                    this.zamanAdi = 'Geçen Hafta'
                    break;
                case '5':
                    this.zamanAdi = 'Bu Ay'
                    break;
                case '6':
                    this.zamanAdi = 'Geçen Ay'
                    break;
                case '7':
                    this.zamanAdi = `${this.tarih1} ve ${this.tarih2} arasında`
                    break;
                default:
                    this.zamanAdi = 'Bu Hafta'
                    break;
            }
        },
        zamanaGoreFiltrele() {
            // 1 = bugün, 2 = dün, 3 = bu hafta, 4 = geçen hafta, 5 = bu ay, 6 = geçen ay
            if (this.zaman != -1) {
                this.tarih1 = moment().format('YYYY-MM-DD')
                this.tarih2 = moment().format('YYYY-MM-DD')
            }
            this.odemeSekilleriGetir()
        },
        tarihFormatla,
        paraFormatla
    }
}
</script>