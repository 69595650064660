<template>
    <canvas :width="width" :height="height" :id="id"></canvas>
</template>

<script>
    /* eslint-disable */
    import Chart from 'chart.js'

    export default {
        name: 'BaseChart',
        props: ['id','width','height','type','title','labels','chartData', 'fill', 'backgroundColor', 'borderColor', 'borderWidth', 'options'],
        watch: {
            chartData: {
                deep: true,
                immediate: false,
                handler: function(newVal, oldVal) {
                    this.initChart()
                }
            }
        },
        data() {
            return {
                myChart: null
            }
        },
        mounted() {
            this.initChart()
        },
        methods: {
            initChart() {
                
                
                if (this.myChart) {
                    try {
                        this.myChart.destroy()
                    } catch {
                        
                    }
                }

                const ctx = document.getElementById(this.id).getContext('2d')

            
                this.myChart = new Chart(ctx, {
                    type: this.type ? this.type : 'pie',
                    data: {
                        labels: this.labels,
                        datasets: [{
                            label: this.title,
                            data: this.chartData,
                            fill: this.fill,
                            backgroundColor: this.backgroundColor,
                            borderColor: this.borderColor,
                            borderWidth: this.borderWidth ? this.borderWidth : 1
                        }]
                    },
                    options: this.options
                });

                this.chartTooltipsRegister()
            },
            chartTooltipsRegister() {
                Chart.pluginService.register({
                    beforeRender: function (chart) {
                        if (chart.config.options.showAllTooltips) {
                            chart.pluginTooltips = [];
                            chart.config.data.datasets.forEach(function (dataset, i) {
                                chart.getDatasetMeta(i).data.forEach(function (sector, j) {
                                    chart.pluginTooltips.push(new Chart.Tooltip({
                                        _chart: chart.chart,
                                        _chartInstance: chart,
                                        _data: chart.data,
                                        _options: chart.options.tooltips,
                                        _active: [sector]
                                    }, chart));
                                });
                            });
                            chart.options.tooltips.enabled = false;
                        }
                    },
                    afterDraw: function (chart, easing) {
                        if (chart.config.options.showAllTooltips) {
                            if (!chart.allTooltipsOnce) {
                                if (easing !== 1) {
                                    return
                                }
                                chart.allTooltipsOnce = true;
                            }
                            chart.options.tooltips.enabled = true;
                            Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
                                tooltip.initialize();
                                tooltip.update();
                                tooltip.pivot();
                                tooltip.transition(easing).draw();
                            });
                            chart.options.tooltips.enabled = false;
                        }
                    }
                })
            }
        }
    }
</script>