<template>
    <div>
        <base-header type="gradient-dark" class="pb-3 pb-3 pt-3 pt-md-5"></base-header>
        <div class="container-fluid mb-2 mt-2">
            <div class="row">
                <div class="col-md-4">
                    <label for="subeler">Şubeler</label>
                    <select @change="zamanaGoreFiltrele" v-model="subeID" class="form-control" id="subeler">
                        <option value="0">Tümü</option>
                        <option v-for="(sube, index) in subeler" :key="index" :value="sube.MusteriID">{{ sube.FirmaAdi }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
            <div class="table-responsive">
                <base-table class="table align-items-center table-flush"
                    :class="type === 'dark' ? 'table-dark': ''"
                    :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                    tbody-classes="list"
                    :data="stokAdetleri">
                    
                    <template slot="columns">
                        <th>Ürün Adı</th>
                        <th>Stok</th>
                        <th>Kritik Stok</th>
                        <th>Depo Adı</th>
                    </template>

                    <template slot-scope="{row}">
                        <td>
                            {{ row.UrunAdi }}
                        </td>
                        <td>
                            {{ sayiFormatla(row.Stok) }}
                        </td>
                        <td>
                            {{ sayiFormatla(row.KritikStok) }}
                        </td>
                        <td>
                            {{ row.DepoAdi }}
                        </td>
                    </template>

                </base-table>
            </div>

            <div class="card-footer d-flex justify-content-end"
                :class="type === 'dark' ? 'bg-transparent': ''">
                <base-pagination :per-page="toplamKayit" @input="sayfaDegis" :value="sayfa" :total="tmpStokAdetleri.length"></base-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import api from '@/api/api'

import paraFormatla from '@/utils/paraFormatla'
import tarihFormatla from '@/utils/tarihFormatla'
import sayiFormatla from '@/utils/sayiFormatla'

export default {
    name: 'StokAdetleriRaporu',
    data() {
        return {
            stokAdetleri: [],
            tmpStokAdetleri: [],
            subeID: 0,
            subeler: [],
            type: 'light',
            toplamKayit: 10,
            sayfa: 1,
        }
    },
    mounted() {
        this.subelerGetir()
    },
    methods: {
        tarihFormatla,
        paraFormatla,
        sayiFormatla,
        zamanaGoreFiltrele() {
            this.stokAdetleriGetir()
        },
        subelerGetir() {
            const subelerData = new FormData()

            const { MusteriNo } = api.getUser()

            subelerData.append('MusteriNo', MusteriNo)

            api
            .subelerGetir(subelerData)
            .then(resp => resp.json())
            .then(data => {
                this.subeler = data[0]

                this.stokAdetleriGetir()
            })
        },
        stokAdetleriGetir() {

            const stokAdetleriData = new FormData()
            if (this.subeID > 0) {
                stokAdetleriData.append('MusteriNo[]', this.subeID)
            } else {
                this.subeler.forEach(sube => {
                    stokAdetleriData.append('MusteriNo[]', sube.MusteriID)
                })
            }

            api
             .stokAdetleriGetir(stokAdetleriData)
             .then(resp => resp.json())
             .then(data => {
                this.stokAdetleri = data
                this.tmpStokAdetleri = data
                this.sayfaDegis(1)
             })
        },
        sayfaDegis(data) {
            this.sayfa = data

            const start = this.sayfa == 1 ? 0 : (this.sayfa - 1) * this.toplamKayit
            const selectTotal = this.toplamKayit * data
            

            this.stokAdetleri = this.tmpStokAdetleri.slice(start, selectTotal)
        },
    }
}
</script>