<template>
    <div>
        <base-header type="gradient-dark" class="pb-3 pb-3 pt-3 pt-md-5">
            <div class="row">
                <div class="col-xl-6 col-lg-6">
                    <stats-card title="Toplam Adet"
                                type="gradient-red"
                                :sub-title="toplamAdet"
                                icon="fas fa-lira-sign"
                                class="mb-4 mb-xl-0"
                    >
                        <template slot="footer">
                            <span class="text-nowrap">{{ zamanAdi }}</span>
                        </template>

                    </stats-card>
                </div>
                <div class="col-xl-6 col-lg-6">
                    <stats-card title="Toplam Tutar"
                                type="gradient-red"
                                :sub-title="toplamTutar"
                                icon="fas fa-lira-sign"
                                class="mb-4 mb-xl-0"
                    >
                        <template slot="footer">
                            <span class="text-nowrap">{{ zamanAdi }}</span>
                        </template>
                        
                    </stats-card>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mb-2 mt-2">
            <div class="row">
                <div class="col-md-4">
                    <label for="subeler">Şubeler</label>
                    <select @change="zamanaGoreFiltrele" v-model="subeID" class="form-control" id="subeler">
                        <option value="0">Tümü</option>
                        <option v-for="(sube, index) in subeler" :key="index" :value="sube.MusteriID">{{ sube.FirmaAdi }}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="tarih-araligi">Tarih Aralığı</label>
                    <select v-model="zaman" @change="zamanaGoreFiltrele" id="tarih-araligi" class="form-control">
                        <option value="1">Bugün</option>
                        <option value="2">Dün</option>
                        <option value="3">Bu Hafta</option>
                        <option value="4">Geçen Hafta</option>
                        <option value="5">Bu Ay</option>
                        <option value="6">Geçen Ay</option>
                        <option value="-1">Özel</option>
                    </select>
                </div>
                <div v-if="zaman == -1" class="col-md-4">
                    <label class="col-md-6 mt-2" for="tarih-1">Başlangıç Tarihi <input @input="zamanaGoreFiltrele" class="form-control mt-2" type="date" v-model="tarih1"/></label>
                        
                    <label class="col-md-6 mt-2" for="tarih-2">Bitiş Tarihi <input @input="zamanaGoreFiltrele" class="form-control mt-2" type="date" v-model="tarih2"/></label>
                </div>
            </div>
        </div>
        <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
            <div class="table-responsive">
                <base-table class="table align-items-center table-flush"
                    :class="type === 'dark' ? 'table-dark': ''"
                    :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                    tbody-classes="list"
                    :data="satisAdetleri">
                    
                    <template slot="columns">
                        <th>Ürün Adı</th>
                        <th>Toplam Adet</th>
                        <th>Toplam Tutar</th>
                        <th>Paket Adeti</th>
                        <th>Paket Tutarı</th>
                        <th>Diğer Adet</th>
                        <th>Diğer Tutar</th>
                    </template>

                    <template slot-scope="{row}">
                        <td>
                            {{ row.AltUrunAdi }}
                        </td>
                        <td>
                            {{ sayiFormatla(row.Adet) }}
                        </td>
                        <td>
                            {{ paraFormatla(row.Tutar) }}
                        </td>
                        <td>
                            {{ sayiFormatla(row.PaketAdet) }}
                        </td>
                        <td>
                            {{ paraFormatla(row.PaketTutar) }}
                        </td>
                        <td>
                            {{ sayiFormatla(row.Adet) }}
                        </td>
                        <td>
                            {{ paraFormatla(row.Tutar) }}
                        </td>
                    </template>

                </base-table>
            </div>

            <div class="card-footer d-flex justify-content-end"
                :class="type === 'dark' ? 'bg-transparent': ''">
                <base-pagination :per-page="toplamKayit" @input="sayfaDegis" :value="sayfa" :total="tmpSatisAdetleri.length"></base-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment'

import api from '@/api/api'

import paraFormatla from '@/utils/paraFormatla'
import tarihFormatla from '@/utils/tarihFormatla'
import sayiFormatla from '@/utils/sayiFormatla'

export default {
    name: 'SatisAdetleriRaporu',
    data() {
        return {
            satisAdetleri: [],
            tmpSatisAdetleri: [],
            subeID: 0,
            subeler: [],
            zaman: 3,
            zamanAdi: '',
            type: 'light',
            toplamKayit: 10,
            sayfa: 1,
            tarih1: moment().format('YYYY-MM-DD'),
            tarih2: moment().format('YYYY-MM-DD'),
            toplamAdet: '0',
            toplamTutar: '0'
        }
    },
    mounted() {
        this.subelerGetir()
    },
    methods: {
        tarihFormatla,
        paraFormatla,
        sayiFormatla,
        zamanaGoreFiltrele() {
            // 1 = bugün, 2 = dün, 3 = bu hafta, 4 = geçen hafta, 5 = bu ay, 6 = geçen ay
            if (this.zaman != -1) {
                this.tarih1 = moment().format('YYYY-MM-DD')
                this.tarih2 = moment().format('YYYY-MM-DD')
            }
            this.satisAdetleriGetir()
        },
        subelerGetir() {
            const subelerData = new FormData()

            const { MusteriNo } = api.getUser()

            subelerData.append('MusteriNo', MusteriNo)

            api
            .subelerGetir(subelerData)
            .then(resp => resp.json())
            .then(data => {
                this.subeler = data[0]

                this.satisAdetleriGetir()
            })
        },
        zamanAdiYukle() {
          
            switch (this.zaman.toString()) {
                case '1':
                    this.zamanAdi = 'Bugün'
                    break;
                case '2':
                    this.zamanAdi = 'Dün'
                    break;
                case '3':
                    this.zamanAdi = 'Bu Hafta'
                    break;
                case '4':
                    this.zamanAdi = 'Geçen Hafta'
                    break;
                case '5':
                    this.zamanAdi = 'Bu Ay'
                    break;
                case '6':
                    this.zamanAdi = 'Geçen Ay'
                    break;
                case '7':
                    this.zamanAdi = `${this.tarih1} ve ${this.tarih2} arasında`
                    break;
                default:
                    this.zamanAdi = 'Bu Hafta'
                    break;
            }
        },
        satisAdetleriGetir() {

            const satisAdetleriData = new FormData()
            if (this.subeID > 0) {
                satisAdetleriData.append('MusteriNo[]', this.subeID)
            } else {
                this.subeler.forEach(sube => {
                    satisAdetleriData.append('MusteriNo[]', sube.MusteriID)
                })
            }

            satisAdetleriData.append('zaman', this.zaman)
            satisAdetleriData.append('baslangic-tarihi', this.tarih1)
            satisAdetleriData.append('bitis-tarihi', this.tarih2)

            this.zamanAdiYukle()

            api
             .satisAdetleriGetir(satisAdetleriData)
             .then(resp => resp.json())
             .then(data => {
                this.satisAdetleri = data
                this.tmpSatisAdetleri = data

                let toplamAdet = 0
                let toplamTutar = 0

                this.tmpSatisAdetleri.forEach(satisAdeti => {
                    toplamAdet += parseFloat(satisAdeti.Adet)
                    toplamTutar += parseFloat(satisAdeti.Tutar)
                })

                this.toplamAdet = this.sayiFormatla(toplamAdet)
                this.toplamTutar = this.paraFormatla(toplamTutar)
                
             })
        },
        sayfaDegis(data) {
            this.sayfa = data

            const start = this.sayfa == 1 ? 0 : (this.sayfa - 1) * this.toplamKayit
            const selectTotal = this.toplamKayit * data
            

            this.satisAdetleri = this.tmpSatisAdetleri.slice(start, selectTotal)
        },
    }
}
</script>