export default function(sayi) {
    try {
        if (sayi >= 0) {
            return new Intl.NumberFormat('tr', { style: 'decimal'}).format(sayi)
        } else {
            return new Intl.NumberFormat('tr', { style: 'decimal'}).format(0)
        }
    } catch (error) {
        return new Intl.NumberFormat('tr', { style: 'decimal'}).format(0)
    }
}