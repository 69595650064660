export default function(sayi) {
    try {
        if (sayi >= 0) {
            return '₺ ' + new Intl.NumberFormat('tr', { style: 'currency', currency: 'TRY' }).format(sayi).replace('₺', '')
        } else {
            return '₺ ' + new Intl.NumberFormat('tr', { style: 'currency', currency: 'TRY' }).format(0).replace('₺', '')
        }
    } catch (error) {
        return '₺ ' + new Intl.NumberFormat('tr', { style: 'currency', currency: 'TRY' }).format(0).replace('₺', '')
    }
}