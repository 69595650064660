<template>
    <div>
        <base-header type="gradient-dark" class="pb-3 pb-3 pt-3 pt-md-5">
            <div class="row">
                <div class="col-xl-6 col-lg-6">
                    <stats-card title="Toplam Tutar"
                                type="gradient-red"
                                :sub-title="toplamTutar"
                                icon="fas fa-lira-sign"
                                class="mb-4 mb-xl-0"
                    >
                        <template slot="footer">
                            <span class="text-nowrap">{{ zamanAdi }}</span>
                        </template>
                        
                    </stats-card>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mb-2 mt-2">
            <div class="row">
                <div class="col-md-4">
                    <label for="subeler">Şubeler</label>
                    <select @change="zamanaGoreFiltrele" v-model="subeID" class="form-control" id="subeler">
                        <option value="0">Tümü</option>
                        <option v-for="(sube, index) in subeler" :key="index" :value="sube.MusteriID">{{ sube.FirmaAdi }}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="tarih-araligi">Tarih Aralığı</label>
                    <select v-model="zaman" @change="zamanaGoreFiltrele" id="tarih-araligi" class="form-control">
                        <option value="1">Bugün</option>
                        <option value="2">Dün</option>
                        <option value="3">Bu Hafta</option>
                        <option value="4">Geçen Hafta</option>
                        <option value="5">Bu Ay</option>
                        <option value="6">Geçen Ay</option>
                        <option value="-1">Özel</option>
                    </select>
                </div>
                <div v-if="zaman == -1" class="col-md-4">
                    <label class="col-md-6 mt-2" for="tarih-1">Başlangıç Tarihi <input @input="zamanaGoreFiltrele" class="form-control mt-2" type="date" v-model="tarih1"/></label>
                        
                    <label class="col-md-6 mt-2" for="tarih-2">Bitiş Tarihi <input @input="zamanaGoreFiltrele" class="form-control mt-2" type="date" v-model="tarih2"/></label>
                </div>
            </div>
        </div>
        <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
            <div class="table-responsive">
                <base-table class="table align-items-center table-flush"
                    :class="type === 'dark' ? 'table-dark': ''"
                    :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                    tbody-classes="list"
                    :data="iptalEdilenler">
                    
                    <template slot="columns">
                        <th>Ürün Adı</th>
                        <th>Adet</th>
                        <th>Fiyat</th>
                        <th>Personel</th>
                        <th>Tarih</th>
                    </template>

                    <template slot-scope="{row}">
                        <td>
                            {{ row.AltUrunAdi }}
                        </td>
                        <td>
                            {{ sayiFormatla(row.Adet) }}
                        </td>
                        <td>
                            {{ paraFormatla(row.Fiyat) }}
                        </td>
                        <td>
                            {{ row.Personel }}
                        </td>
                        <td>
                            {{ tarihFormatla(row.Tarih, 'DD.MM.YYYY') }}
                        </td>
                    </template>

                </base-table>
            </div>

            <div class="card-footer d-flex justify-content-end"
                :class="type === 'dark' ? 'bg-transparent': ''">
                <base-pagination :per-page="toplamKayit" @input="sayfaDegis" :value="sayfa" :total="tmpIptalEdilenler.length"></base-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment'

import api from '@/api/api'

import paraFormatla from '@/utils/paraFormatla'
import tarihFormatla from '@/utils/tarihFormatla'
import sayiFormatla from '@/utils/sayiFormatla'

export default {
    name: 'IptalEdilenlerRaporu',
    data() {
        return {
            iptalEdilenler: [],
            tmpIptalEdilenler: [],
            subeID: 0,
            subeler: [],
            zaman: 3,
            zamanAdi: '',
            type: 'light',
            toplamKayit: 10,
            sayfa: 1,
            tarih1: moment().format('YYYY-MM-DD'),
            tarih2: moment().format('YYYY-MM-DD'),
            toplamTutar: '0'
        }
    },
    mounted() {
        this.subelerGetir()
    },
    methods: {
        tarihFormatla,
        paraFormatla,
        sayiFormatla,
        zamanaGoreFiltrele() {
            // 1 = bugün, 2 = dün, 3 = bu hafta, 4 = geçen hafta, 5 = bu ay, 6 = geçen ay
            if (this.zaman != -1) {
                this.tarih1 = moment().format('YYYY-MM-DD')
                this.tarih2 = moment().format('YYYY-MM-DD')
            }
            this.iptalEdilenlerGetir()
        },
        subelerGetir() {
            const subelerData = new FormData()

            const { MusteriNo } = api.getUser()

            subelerData.append('MusteriNo', MusteriNo)

            api
            .subelerGetir(subelerData)
            .then(resp => resp.json())
            .then(data => {
                this.subeler = data[0]

                this.iptalEdilenlerGetir()
            })
        },
        zamanAdiYukle() {
          
            switch (this.zaman.toString()) {
                case '1':
                    this.zamanAdi = 'Bugün'
                    break;
                case '2':
                    this.zamanAdi = 'Dün'
                    break;
                case '3':
                    this.zamanAdi = 'Bu Hafta'
                    break;
                case '4':
                    this.zamanAdi = 'Geçen Hafta'
                    break;
                case '5':
                    this.zamanAdi = 'Bu Ay'
                    break;
                case '6':
                    this.zamanAdi = 'Geçen Ay'
                    break;
                case '7':
                    this.zamanAdi = `${this.tarih1} ve ${this.tarih2} arasında`
                    break;
                default:
                    this.zamanAdi = 'Bu Hafta'
                    break;
            }
        },
        /**tur = 1 ise toplam gelir gider, 0 ise tablo listesi */
        iptalEdilenlerGetir() {

            const iptalEdilenlerData = new FormData()
            if (this.subeID > 0) {
                iptalEdilenlerData.append('MusteriNo[]', this.subeID)
            } else {
                this.subeler.forEach(sube => {
                    iptalEdilenlerData.append('MusteriNo[]', sube.MusteriID)
                })
            }

            iptalEdilenlerData.append('zaman', this.zaman)
            iptalEdilenlerData.append('baslangic-tarihi', this.tarih1)
            iptalEdilenlerData.append('bitis-tarihi', this.tarih2)
            

            this.zamanAdiYukle()

            api
             .iptalEdilenlerGetir(iptalEdilenlerData)
             .then(resp => resp.json())
             .then(data => {
                this.iptalEdilenler = data.kasa
                this.tmpIptalEdilenler = data.kasa

                let toplamTutar = 0

                this.tmpIptalEdilenler.forEach(iptalEdilen => {
                    toplamTutar += parseFloat(iptalEdilen.Fiyat)
                })

                this.toplamTutar = this.paraFormatla(toplamTutar)
                
             })
        },
        sayfaDegis(data) {
            this.sayfa = data

            const start = this.sayfa == 1 ? 0 : (this.sayfa - 1) * this.toplamKayit
            const selectTotal = this.toplamKayit * data
            

            this.iptalEdilenler = this.tmpIptalEdilenler.slice(start, selectTotal)
        },
    }
}
</script>